import { Component, OnInit, OnDestroy } from '@angular/core';
import { MReportsService } from '../../m-reports.service';

import { SchemesService } from '../../../entities/entities.service';
import { Subscription } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { Entity } from '../../../../plex/entities/entities.model';
import { Store } from '@ngrx/store';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { take } from 'rxjs/operators';
import moment from 'moment';

declare const toastr: any;

@Component({
	selector: 'app-current-balances',
	templateUrl: './current-balances.component.html',
	styleUrls: ['./current-balances.component.scss'],
})
export class CurrentBalancesComponent implements OnInit, OnDestroy {
	currentData: any;
	available: any;
	entity: any;
	entityId: string;
	private subscription = new Subscription();
	isLoadingBalances = true;

	isEditBuffer = false;
	loadingBuffer = false;
	isEditOverdraft = false;
	loadingOverDraft = false;

	constructor(private mReportsService: MReportsService, private schemeService: SchemesService, private currencyPipe: CurrencyPipe, private store: Store) {}

	ngOnInit() {
		this.store
			.select(selectEntityId)
			.pipe(take(1))
			.subscribe(id => {
				this.entityId = id;
			});

		this.subscription.add(
			this.schemeService.getEntityDetails(this.entityId).subscribe((entityData: Entity) => {
				this.entity = entityData;
				this.getCurrentBalances();
			})
		);
	}

	formatAmount(amount) {
		if (amount) {
			if (typeof amount === 'string') {
				return this.currencyPipe.transform(parseFloat(amount.replace(/ /g, '')), 'R ').replace(/,/g, ' ');
			} else {
				return this.currencyPipe.transform(parseFloat(amount), 'R ').replace(/,/g, ' ');
			}
		} else {
			return 'R 0.00';
		}
	}

	async getCurrentBalances() {
		this.isLoadingBalances = true;
		const result = await this.mReportsService.calculateSchemeAvailableBalances([8400, 8407, 8405], this.entity);
		this.currentData = result.accounts.map(item => {
			item.total = this.formatAmount(item.total);
			return item;
		});
		this.available = result.available;
		this.isLoadingBalances = false;
	}

	updateOverdraft(overdraft) {
		this.loadingOverDraft = true;
		this.entity['overdraft'] = overdraft;
		this.schemeService
			.updateEntity(this.entity)
			.then(() => {
				this.isEditOverdraft = false;
				toastr.success('Overdraft successfully updated.');
				this.isEditOverdraft = false;
				this.loadingOverDraft = false;
			})
			.catch(err => {
				toastr.error('There was an error updating Overdraft!');
				this.isEditOverdraft = false;
				this.loadingOverDraft = false;
				console.log(err);
			});
	}

	updateBuffer(bufferAmount) {
		const entity = {};
		this.loadingBuffer = true;
		this.entity['buffer'] = bufferAmount;
		this.entity['id'] = this.entityId;

		this.schemeService
			.updateEntity(this.entity)
			.then(() => {
				toastr.success('Buffer successfully updated.');
				this.isEditBuffer = false;
				this.loadingBuffer = false;
			})
			.catch(err => {
				toastr.error('There was an error updating buffer!');
				this.isEditBuffer = false;
				this.loadingBuffer = false;
				console.log(err);
			});
	}

	updateBufferOverride(bufferOverride) {
		const entity = {};
		this.loadingBuffer = true;
		this.entity['bufferOverride'] = bufferOverride.toLowerCase();
		this.entity['buffer'] = '10000';
		this.entity['id'] = this.entityId;

		this.schemeService
			.updateEntity(this.entity)
			.then(() => {
				toastr.success('Buffer successfully updated.');
				this.isEditBuffer = false;
				this.loadingBuffer = false;
			})
			.catch(err => {
				toastr.error('There was an error updating buffer!');
				this.isEditBuffer = false;
				this.loadingBuffer = false;
				console.log(err);
			});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
