import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthenticationService } from '@auth/_services';
import { environment } from '@environments/environment';
import { UserEntity } from 'src/app/_state/user-entity/user-entity.model';
import { setUserEntities } from 'src/app/_state/user-entity/user-entity.actions';

@Injectable()
export class UserEntityGuard implements CanActivate {
	private userEntities?: UserEntity[];

	constructor(private store: Store, private authenticationService: AuthenticationService, public afStore: AngularFirestore, private router: Router) {}

	canActivate(next: ActivatedRouteSnapshot) {
		return from(this.lazyLoadUserEntities()).pipe(
			switchMap(() => {
				const { entityId } = next.params;

				// Exit checking on Olympus.
				if (environment.product === 'olympus') {
					return of(true);
				}

				// First entityId on plex is 'entities'.
				if (entityId === 'entities') {
					return of(true);
				}

				// Allow routing to product entityId.
				if (entityId === environment.product) {
					return of(true);
				}

				const isUserEntity = this.userEntities.some(({ id, uid }) => {
					return (id || uid) === entityId;
				});

				if (isUserEntity) {
					return of(true);
				}

				console.log('Navigation prevented by UserEntityGuard.');
				this.router.navigateByUrl('/');
				return of(false);
			})
		);
	}

	private async lazyLoadUserEntities() {
		if (!this.userEntities) {
			const userEntities = await this.getUsersEntities();
			this.userEntities = userEntities;
			this.store.dispatch(
				setUserEntities({
					userEntities: userEntities,
				})
			);
		}
	}

	private async getUsersEntities() {
		const userId = this.authenticationService.userId;

		const userEntities = await this.afStore
			.collection<UserEntity>(`users/${userId}/entities`)
			.ref // .where('active', '==', true) // ADD BACK IF THERE ARE ISSUES RELATED TO https://noldor.atlassian.net/browse/WPM-120
			.where('product', '==', environment.product)
			.orderBy('name', 'asc')
			.get();

		return userEntities.docs.map(doc => {
			const data = doc.data();
			const id = doc.id;

			const { permissions } = data;

			let isAdmin = false;
			let isViewOnly = false;

			if (permissions) {
				if (permissions.includes('admin')) {
					isAdmin = true;
				}

				if (permissions.includes('view_only')) {
					isViewOnly = true;
				}
			}

			// Change detection is mutating the original object, which ngrx store doesn't like.
			return JSON.parse(
				JSON.stringify({
					...data,
					id,
					isAdmin,
					isViewOnly,
				})
			);
		});
	}
}
