import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { FirebaseApp } from '@angular/fire';
import { environment } from '../../../../../../environments/environment';
import { RegOwner } from './registered-owner.model';
import { User } from '../users/user.model';
import { take } from 'rxjs/operators';
import { UsersService } from '../users/users.service';
import { Property } from '@plex/property_models/interfaces/property.model';
import { HistoryField, ModifiedHistoryField, RegisteredOwnerHistoryEntry } from '@plex/property_models/interfaces/property_history.model';
import { AuthenticationService } from '../../../../../auth/_services/authentication.service';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RegOwnerHistoryEntry } from './models/index.barrel';
import { Account } from '@plex/fin/models/account';

declare let toastr: any;

export type LinkOwnerAndUserRequest = {
	owner?:
		| {
				name: string;
				id: string;
		  }
		| RegOwner;
	userId?: string;
	entityId: string;
	user?:
		| {
				firstname: string;
				surname: string;
				email: string;
				uid: string;
		  }
		| User;
	ownerId?: string;
	currentUser: User;
	environment: EnvDetails;
	notifyUpdate: boolean;
	isAddUserToOwner: boolean;
};

type AddRegisteredOwnerRequest = {
	userId: string;
	entityId: string;
	newOwnerData: RegOwner;
	propertyId: string;
	environment: EnvDetails;
	sendEmail: boolean;
	currentUser: User;
	existingAccountId?: string;
	existingAccountName: string;
};

type AddRegisteredOwnerResponse = {
	new?: string;
	existing?: string;
};

@Injectable()
export class RegisteredOwnersService {
	entityPropertiesCollection: AngularFirestoreCollection<Property[]>;
	propertyDoc: AngularFirestoreDocument<Property>;
	registeredOwnerDoc: AngularFirestoreDocument<RegOwner>;
	usersCollection: AngularFirestoreCollection<User[]>;
	entityId: any;

	constructor(
		public afs: AngularFirestore,
		public router: Router,
		public fb: FirebaseApp,
		private usersService: UsersService,
		private functions: AngularFireFunctions,
		private authSrvc: AuthenticationService,
		private store: Store
	) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
	}

	// REGISTERED OWNER

	fetchEntityRegisteredOwners() {
		const registeredOwnersRef = this.afs.collection(`entities/${this.entityId}/registered_owners`, ref => ref.where('active', '==', true));

		return registeredOwnersRef.valueChanges({ idField: 'uid' });
	}

	fetchOwnerHistory(ownerId) {
		const historyCollection = this.afs.collection(`entities/${this.entityId}/registered_owners/${ownerId}/history`, ref => ref.orderBy('created', 'desc'));

		return historyCollection.valueChanges({ idField: 'id' }) as Observable<RegOwnerHistoryEntry[]>;
	}

	fetchOwnerHistoryOwner(ownerId) {
		const userDoc = this.afs.doc(`entities/${this.entityId}/registered_owners/${ownerId}`);
		return userDoc.valueChanges();
	}

	fetchEntityRemovedRegisteredOwners() {
		const registeredOwnersRef = this.afs.collection(`entities/${this.entityId}/registered_owners`, ref => ref.where('active', '==', false));

		return registeredOwnersRef.valueChanges({ idField: 'uid' });
	}

	fetchRegisteredOwner(regOwnerId: string) {
		return this.afs.doc(`entities/${this.entityId}/registered_owners/${regOwnerId}`).valueChanges();
	}

	fetchRegisteredOwnerProperties(regOwnerId) {
		const registeredOwnersPropertiesRef = this.afs.collection(`entities/${this.entityId}/registered_owners/${regOwnerId}/properties`, ref => ref.where('active', '==', true));

		return registeredOwnersPropertiesRef.valueChanges({ idField: 'uid' });
	}

	fetchAllRegisteredOwnerProperties(regOwnerId): Observable<Property[]> {
		const registeredOwnersPropertiesRef = this.afs.collection(`entities/${this.entityId}/registered_owners/${regOwnerId}/properties`);

		return registeredOwnersPropertiesRef.valueChanges({ idField: 'uid' });
	}

	fetchRegisteredOwnerPropertiesList(propertyId) {
		return this.afs.doc(`entities/${this.entityId}/properties/${propertyId}`).valueChanges();
	}

	fetchAllProperties() {
		const propertiesRef = this.afs.collection(`entities/${this.entityId}/properties`, ref => ref.where('active', '==', true));

		return propertiesRef.valueChanges({ idField: 'uid' });
	}

	async addRegisteredOwner(registeredOwner: RegOwner, propertyId, existingAccountId?, existingAccountName?) {
		const { client, admin, product } = environment;
		const response = await this.functions
			.httpsCallable<AddRegisteredOwnerRequest, AddRegisteredOwnerResponse>('plexff-regOwner-core-addRegisteredOwner')({
				newOwnerData: registeredOwner,
				propertyId,
				entityId: this.entityId,
				userId: this.authSrvc.userDetails.id || this.authSrvc.userDetails.uid,
				existingAccountId,
				sendEmail: true,
				environment: { client, admin, product },
				currentUser: this.authSrvc.userDetails,
				existingAccountName,
			})
			.toPromise()
			.catch(err => {
				console.error(err);
				throw err;
			});
		if (response.new) {
			return response.new;
		}
		throw 'Registered owner already exists';
	}

	sendRegisteredOwnerAddedNotification(registeredOwnerData) {
		const pendingRegisteredOwnerAddNotificationRef = this.afs.collection('pending');
		const userDetails = this.authSrvc.userDetails;
		const entityId = this.entityId;

		pendingRegisteredOwnerAddNotificationRef.add({
			request: 'emailRegisteredOwnerAddedNotification',
			ownerName: registeredOwnerData.name,
			entityId: this.entityId,
			environmentAdmin: environment.admin,
			product: environment.product,
			addedBy: `${userDetails.firstname} ${userDetails.surname}`,
		});
	}

	updateRegisteredOwner(registeredOwner: RegOwner, regOwnerId) {
		this.registeredOwnerDoc = this.afs.doc(`entities/${this.entityId}/registered_owners/${regOwnerId}`);

		return this.registeredOwnerDoc.update(registeredOwner);
	}

	public async addHistoryLogToRegisteredOwner(
		changed: Array<HistoryField | ModifiedHistoryField>,
		registeredOwnerId: string,
		historyType: 'user' | 'owner' | 'file' | 'note' | 'task' | 'property' | 'account',
		notifyUpdate: boolean
	): Promise<DocumentReference> {
		const registeredOwnerHistoryCollection = this.afs.collection(`entities/${this.entityId}/registered_owners/${registeredOwnerId}/history`);

		const userDetails = this.authSrvc.userDetails;
		const entityId = this.entityId;

		const logData: RegisteredOwnerHistoryEntry = {
			created: Date.now(),
			historyType,
			changed,
			registeredOwnerId,
			entityId,
			changedById: userDetails.uid,
			changedByName: `${userDetails.firstname} ${userDetails.surname}`,
			client: environment.client,
			admin: environment.admin,
			product: environment.product,
		};

		try {
			if (notifyUpdate) this.addPendingRegisteredOwnerUpdates(logData);
			return await registeredOwnerHistoryCollection.add(logData);
		} catch (error) {
			throw new Error(error);
		}
	}

	addPendingRegisteredOwnerUpdates(logData) {
		const pendingUpdatesCollection = this.afs.collection(`pending`);
		logData.request = 'notifyReceiveRegisteredOwnerUpdates';
		logData.entityId = this.entityId;
		logData.created = new Date();
		pendingUpdatesCollection.add(logData).catch(err => {
			console.log(err);
		});
	}

	addPropertyToRegisteredOwner(regOwnerId: string, propertyId: string, propertNumber, regOwners) {
		const userID = sessionStorage.getItem('userId');
		const propertyDoc = this.afs.doc(`entities/${this.entityId}/properties/${propertyId}/registered_owners/${regOwnerId}`);
		const ownerDoc = this.afs.doc(`entities/${this.entityId}/registered_owners/${regOwnerId}/properties/${propertyId}`);

		return propertyDoc
			.set(
				{
					active: true,
					registeredOwnerId: regOwnerId,
					createdBy: userID,
					created: Date.now(),
					propert_number: propertNumber,
				},
				{ merge: true }
			)
			.then(() => {
				return ownerDoc.set(
					{
						active: true,
						propertyId: propertyId,
						ownerId: regOwnerId,
						createdBy: userID,
						created: Date.now(),
						propert_number: propertNumber,
					},
					{ merge: true }
				);
			});
	}

	removePropertyFromRegOwner(regOwnerId: string, propertyId: string, regOwners) {
		const propertyDoc = this.afs.doc(`entities/${this.entityId}/properties/${propertyId}/registered_owners/${regOwnerId}`);
		const ownerDoc = this.afs.doc(`entities/${this.entityId}/registered_owners/${regOwnerId}/properties/${propertyId}`);
		return propertyDoc.update({ active: false }).then(() => {
			return ownerDoc.update({ active: false });
		});
	}

	addTypeToRegisteredOwner(ownerId, propertyId, value) {
		const ownerDetailsAssoc = {} as RegOwner;
		ownerDetailsAssoc.owner_type = value.addType;

		const addTypeToOwnerAssoc = this.afs
			.collection('entities')
			.doc(this.entityId)
			.collection('registered_owners')
			.doc(ownerId)
			.collection('properties')
			.doc(propertyId)
			.set(ownerDetailsAssoc, { merge: true });

		const addTypeToPropertyOwner = this.afs
			.collection('entities')
			.doc(this.entityId)
			.collection('properties')
			.doc(propertyId)
			.collection('registered_owners')
			.doc(ownerId)
			.set(ownerDetailsAssoc, { merge: true });

		const addTypeToOwner = this.afs.collection('entities').doc(this.entityId).collection('registered_owners').doc(ownerId).set(ownerDetailsAssoc, { merge: true });

		return Promise.all([addTypeToOwnerAssoc, addTypeToOwner, addTypeToPropertyOwner]);
	}

	removeOwnerType(ownerId, propertyId) {
		const ownerDetailsAssoc = {} as RegOwner;
		ownerDetailsAssoc.owner_type = '';

		const removeTypeFromOwnerAssoc = this.afs
			.collection('entities')
			.doc(this.entityId)
			.collection('registered_owners')
			.doc(ownerId)
			.collection('properties')
			.doc(propertyId)
			.set(ownerDetailsAssoc, { merge: true });

		const removeTypeFromOwner = this.afs.collection('entities').doc(this.entityId).collection('registered_owners').doc(ownerId).set(ownerDetailsAssoc, { merge: true });

		return Promise.all([removeTypeFromOwnerAssoc, removeTypeFromOwner]);
	}

	validationHandler(handleData, propertyId) {
		const userID = sessionStorage.getItem('userId');
		const fetchRegisteredOwners = () =>
			new Promise(resolve => {
				this.fetchEntityRegisteredOwners().subscribe(registeredOwnersData => {
					let tmpOwnersArray = registeredOwnersData.map(regOwner => {
						return regOwner;
					});
					resolve(tmpOwnersArray);
				});
			});

		const handleCombineRelated = (ownersData, getData, propertyID) =>
			new Promise((resolve, reject) => {
				let tmpOwnersData = ownersData;
				let formData = getData;

				const registeredOwnersCollection = this.afs.collection(`entities/${this.entityId}/registered_owners`);
				formData.active = true;
				formData.createdBy = userID;

				let tmpArr = tmpOwnersData.filter(item => {
					return item.name == formData.name;
				});
				if (tmpArr.length > 0) {
					reject(`Registered owner ${formData.name} already exists!`);
				} else {
					// OWNER CAN BE ADDED
					if (propertyID != '') {
						// HAS PROPERTY ID - FIND PROPERTY
						this.fetchRegisteredOwnerPropertiesList(propertyID)
							.pipe(take(1))
							.subscribe(propertyData => {
								if (propertyData != null) {
									// PROPERTY FOUND
									registeredOwnersCollection.add(formData).then((result: any) => {
										let tmpOwnerId = result.id;
										const ownersPropertiesDoc = this.afs.doc(`entities/${this.entityId}/registered_owners/${tmpOwnerId}/properties/${propertyID}`);
										return this.afs
											.doc(`entities/${this.entityId}/properties/${propertyId}`)
											.ref.get()
											.then((propertyDoc: any) => {
												let tmpProperty = propertyDoc.data();
												return ownersPropertiesDoc
													.set(
														{
															active: true,
															propertyId: propertyId,
															ownerId: tmpOwnerId,
															owner_type: '',
															createdBy: userID,
															created: Date.now(),
															property_number: tmpProperty.property_number,
														},
														{ merge: true }
													)
													.then(() => {
														const propertyDoc = this.afs.doc(`entities/${this.entityId}/properties/${propertyId}/registered_owners/${tmpOwnerId}`);
														propertyDoc
															.set(
																{
																	active: true,
																	registeredOwnerId: tmpOwnerId,
																	createdBy: userID,
																	created: Date.now(),
																	property_number: tmpProperty.property_number,
																},
																{ merge: true }
															)
															.then(() => {
																this.router.navigate([`/${this.entityId}/properties/edit/${propertyID}`]);
																resolve('Registered owner added to property');
															});
													});
											});
									});
								} else {
									// PROPERTY NOT FOUND - REJECT
									reject(`Property ${propertyID} was not found!`);
								}
							});
					} else {
						registeredOwnersCollection.add(formData).then((result: any) => {
							resolve(result);
						});
					}
				}
			});

		const getResults = async (getData, propertyId) => {
			let ownersData = await fetchRegisteredOwners();
			return handleCombineRelated(ownersData, getData, propertyId);
		};

		return getResults(handleData, propertyId)
			.then((result: any) => {
				return result;
			})
			.catch(err => {
				toastr.error(err);
				return err;
			});
	}

	//ACCOUNTS
	addAccountToRegOwner(regOwnerId, accountData) {
		const regOwnerRef = this.afs.collection('entities').doc(this.entityId).collection('registered_owners').doc(regOwnerId).collection('accounts').doc(accountData.id);

		return regOwnerRef
			.set(accountData, { merge: true })
			.then(() => {
				if (environment.product === 'whitfields') {
					this.addAssociatedOwnerDetails(accountData.name, regOwnerId, 'Debtor Account', 'account');
				}
			})
			.catch(function (error) {
				console.log('Error associating account to property:', error);
			});
	}

	getUsersForRegOwner(regOwnerId) {
		return this.afs
			.collection('entities')
			.doc(this.entityId)
			.collection('registered_owners')
			.doc(regOwnerId)
			.collection('users', ref => ref.where('active', '==', true))
			.valueChanges({ idField: 'id' });
	}

	getAllUsersForRegOwner(regOwnerId) {
		return this.afs.collection('entities').doc(this.entityId).collection('registered_owners').doc(regOwnerId).collection('users').valueChanges({ idField: 'id' });
	}

	getOwnersForUser(userId) {
		return this.afs.collection(`users/${userId}/entities/${this.entityId}/registered_owners`, ref => ref.where('active', '==', true)).valueChanges({ idField: 'id' });
	}

	getAllOwnersForUser(userId): Observable<RegOwner[]> {
		return this.afs.collection(`users/${userId}/entities/${this.entityId}/registered_owners`).valueChanges({ idField: 'id' });
	}

	unlinkUserOwner(ownerId, userId) {
		const removeUserOwner = this.afs.doc(`users/${userId}/entities/${this.entityId}/registered_owners/${ownerId}`).set({ active: false }, { merge: true });
		const removeOwnerUser = this.afs.doc(`entities/${this.entityId}/registered_owners/${ownerId}/users/${userId}`).set({ active: false }, { merge: true });
		return Promise.all([removeUserOwner, removeOwnerUser]);
	}

	addOwnerToUser(owner, user) {
		return this.functions
			.httpsCallable<LinkOwnerAndUserRequest>('plexff-regOwner-core-handlelinkOwnerAndUser')({
				entityId: this.entityId,
				environment,
				notifyUpdate: true,
				owner,
				user,
				currentUser: this.authSrvc.userDetails,
				isAddUserToOwner: false,
			})
			.toPromise();
	}

	addUserToOwner(user: User, owner: RegOwner) {
		return this.functions
			.httpsCallable<LinkOwnerAndUserRequest>('plexff-regOwner-core-handlelinkOwnerAndUser')({
				entityId: this.entityId,
				environment,
				notifyUpdate: true,
				user,
				owner,
				currentUser: this.authSrvc.userDetails,
				isAddUserToOwner: true,
			})
			.toPromise();
	}

	removeAccountFromRegOwner(regOwnerId, accountId, account?) {
		const regOwnerRef = this.afs.collection('entities').doc(this.entityId).collection('registered_owners').doc(regOwnerId).collection('accounts').doc(accountId);

		return regOwnerRef.set({ active: false }, { merge: true }).then(() => {
			if (account && environment.product === 'whitfields') {
				this.removeAssociatedRegOwnerDetails(account.name, regOwnerId, 'Debtor Account', 'account');
			}
		});
	}

	getAccountsForRegOwner(regOwnerId) {
		return this.afs
			.collection(`entities/${this.entityId}/registered_owners/${regOwnerId}/accounts`, ref => ref.orderBy('name', 'asc').where('active', '==', true))
			.valueChanges({ idField: 'id' });
	}

	getAllAccountsForRegOwner(regOwnerId): Observable<Account[]> {
		return this.afs.collection(`entities/${this.entityId}/registered_owners/${regOwnerId}/accounts`, ref => ref.orderBy('name', 'asc')).valueChanges({ idField: 'id' });
	}

	getPendingDocuments(propertyId, userId, ownerId) {
		const statusRef = this.afs.collection(`entities/${this.entityId}/properties/${propertyId}/pending`, ref => ref.where('active', '==', true)).valueChanges({ idField: 'id' });
		const userIdRef = this.afs
			.collection(`entities/${this.entityId}/properties/${propertyId}/pending`, ref => ref.where('userId', '==', userId))
			.valueChanges({ idField: 'id' });
		const regOwnerRef = this.afs
			.collection(`entities/${this.entityId}/properties/${propertyId}/pending`, ref => ref.where('regOwnerId', '==', ownerId))
			.valueChanges({ idField: 'id' });

		/*  return combineLatest<any[]>(statusRef, userIdRef, regOwnerRef).pipe(map(a => {
            const combinedList = a.reduce((acc, cur) => acc.concat(cur));
            console.log("TCL: getPendingDocuments -> combinedList", combinedList)
            return combinedList;
        })); */

		return this.afs
			.collection(`entities/${this.entityId}/properties/${propertyId}/pending`, ref =>
				ref.where('active', '==', true).where('userId', '==', userId).where('regOwnerId', '==', ownerId)
			)
			.valueChanges({ idField: 'id' });
	}

	updateRegisteredOwnerAssociation(details, ownerId, propertyId) {
		const updatePropertyOwner = this.afs.doc(`entities/${this.entityId}/properties/${propertyId}/registered_owners/${ownerId}`).update(details);
		const updateOwnerProperty = this.afs.doc(`entities/${this.entityId}/registered_owners/${ownerId}/properties/${propertyId}`).update(details);
		return Promise.all([updatePropertyOwner, updateOwnerProperty]);
	}

	async updateRegisteredOwnerAssociationAccount(details, registeredOwner, accountId) {
		const { legal_status, id } = registeredOwner;
		const newStatus = details.legal_status;
		const entityPath = `entities/${this.entityId}`;
		const fieldAction = !legal_status && newStatus ? 'added' : 'modified';
		const changes = fieldAction === 'added' ? { value: newStatus } : { from: legal_status || '', to: newStatus };
		const updateAccountOwner = this.afs.doc(`${entityPath}/fin/debtors/list/${accountId}/registered_owners/${id}`).update(details);
		const updateOwnerAccount = this.afs.doc(`${entityPath}/registered_owners/${id}/accounts/${accountId}`).update(details);
		const addHistoryLogToRegOwner = this.addHistoryLogToRegisteredOwner([{ field: 'legal_status', ...changes, fieldAction } as ModifiedHistoryField], id, 'account', true);
		return await Promise.all([updateAccountOwner, updateOwnerAccount, addHistoryLogToRegOwner]);
	}

	updateAssociatedRegOwnerDetails(regOwnerId, property, changedFields, type) {
		const fields: Array<ModifiedHistoryField | HistoryField> = changedFields.map((field: HistoryField): ModifiedHistoryField | HistoryField => {
			if (property[field.field]) {
				return {
					field: field.field,
					from: property[field.field],
					to: field.value,
					fieldAction: 'modified',
				} as ModifiedHistoryField;
			} else {
				return {
					field: field.field,
					value: field.value,
					fieldAction: 'added',
				} as HistoryField;
			}
		});
		this.addHistoryLogToRegisteredOwner(fields, regOwnerId, type, true);
	}

	removeAssociatedRegOwnerDetails(value, ownerId, association, type) {
		const changedField: HistoryField = {
			field: `Associated ${association} Removed`,
			value: `${value}`,
			fieldAction: 'removed',
		};
		this.addHistoryLogToRegisteredOwner([changedField], ownerId, type, true);
	}

	addAssociatedOwnerDetails(value, ownerId, association, type) {
		const changedField: HistoryField = {
			field: `Associated ${association} Added`,
			value: `$${value}`,
			fieldAction: 'added',
		};
		this.addHistoryLogToRegisteredOwner([changedField], ownerId, type, true);
	}
}
