import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as firebase from 'firebase';
import { NgxPermissionsService } from 'ngx-permissions';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { setEntityData, setEntityId, setEntityWhitfieldsPrefix, setProductAllowedEntities } from 'src/app/_state/entity/entity.actions';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { IncomingEntity } from '../../plex/entities/entities.model';

import { environment } from '../../../../../../../src/environments/environment';
import { AuthenticationService } from '../../../../../auth/_services/authentication.service';
import { GenerateThumbnailService } from '../../plex/_services/generate-thumbnail.service';
import { AuditLogService } from '../../plex/audit-log/audit-log.service';
import { Entity } from './entities.model';
import { User } from '@plex/users/user.model';

declare let toastr: any;

@Injectable({
	providedIn: 'root',
})
export class SchemesService {
	entityDoc: AngularFirestoreDocument<Entity>;
	entityTemp: AngularFirestoreDocument<any>;
	entity = new Subject<Entity>();
	currentEntity: Entity;
	currentEntityId: string;
	entityId: string;
	permissions = new Subject();
	permissionsList: any;
	userPermissions: any;
	permissionsSubscription: Subscription;

	sponsorsCollection: AngularFirestoreCollection<any>;
	galleryCollection: AngularFirestoreCollection<any>;
	deleteImage: AngularFirestoreDocument<any>;
	task: AngularFireUploadTask;
	itemCollection: AngularFirestoreCollection<Entity>;
	progressBarValue;
	private user?: User;

	constructor(
		private auditLogService: AuditLogService,
		public afStore: AngularFirestore,
		private storage: AngularFireStorage,
		private permissionsService: NgxPermissionsService,
		private auth: AuthenticationService,
		public generateThumbnailService: GenerateThumbnailService,
		private store: Store
	) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
		this.auth.user.subscribe(user => {
			this.user = user;
		});
	}

	async getFeatureLock() {
		const getter = await this.afStore.doc('/amiti/config/products/whitfields').ref.get();
		return getter.data();
	}

	/**
	 * Do not call .get on this result! Only use as observable.
	 */
	private getEntitiesCollection(userId?: string) {
		const uid = userId || this.user.uid;
		const afsCollection = this.afStore.collection<Partial<Entity>>(`users/${uid}/entities`, ref => {
			return ref.where('active', '==', true).where('product', '==', environment.product).orderBy('name', 'asc');
		});

		delete afsCollection.get;
		return afsCollection as Omit<AngularFirestoreCollection<Partial<Entity>>, 'get'>;
	}

	public getUsersEntities(userId?: string) {
		return this.getEntitiesCollection(userId).valueChanges({ idField: 'id' });
	}

	getAllowedEntities(): Promise<string[]> {
		return new Promise(res => {
			this.afStore
				.collection(`amiti/config/allowedEntities`)
				.ref.get()
				.then(docs => {
					const allowedEntities = [];
					docs.forEach(doc => {
						allowedEntities.push(doc.id);
					});
					this.store.dispatch(setProductAllowedEntities({ allowedEntities: allowedEntities }));
				});
		});
	}

	setEntityId(entityId, url?) {
		const userId = sessionStorage.getItem('userId');
		// get entity if prefix matches
		return new Promise((res, rej) => {
			this.afStore
				.doc(`users/${userId}/entities/${entityId}`)
				.valueChanges()
				.pipe(take(1))
				.subscribe(entityA => {
					if (entityA) {
						let entityRef = this.afStore.doc(`entities/${entityId}`).ref;
						return entityRef.get().then(snapShot => {
							const entity = snapShot.data();
							entity['uid'] = entityId;
							this.setEntity(entity).then(() => {
								res('');
							});
						});
					} else {
						if (entityId !== 'entities' && entityId !== 'serve' && entityId !== 'plex') {
							if (environment.product === 'whitfields') {
								this.afStore
									.doc(`users/${userId}/entities/whitfields`)
									.valueChanges()
									.pipe(take(1))
									.subscribe(entityB => {
										let entityRef = this.afStore.doc(`entities/${entityId}`).ref;
										return entityRef.get().then(snapShot => {
											const entity = snapShot.data();

											entity['uid'] = entityId;
											this.setEntity(entity).then(() => {
												res('');
											});
										});
									});
							} else {
								let entityRef = this.afStore.doc(`entities/${entityId}`).ref;
								return entityRef.get().then(snapShot => {
									const entity = snapShot.data();
									entity['uid'] = entityId;
									this.setEntity(entity).then(() => {
										res('');
									});
								});
							}
						} else {
							this.store.dispatch(setEntityId({ entityId: environment.product }));
							if (environment.product === 'whitfields') {
								this.store.dispatch(setEntityWhitfieldsPrefix({ whitfieldsPrefix: 'WPM' }));
							}
							res('');
						}
					}
				});
		});
	}

	prefixCheck(prefix: string) {
		let collref = this.afStore.collection('entities').ref;
		let queryref = collref.where('prefix', '==', prefix);
		return queryref.get().then(snapShot => {
			return snapShot;
		});
	}

	domainCheck(q: string) {
		let collref = this.afStore.collection('entities').ref;
		let queryref = collref.where('domain', '==', q);
		return queryref.get().then(snapShot => {
			return snapShot;
		});
	}

	//FETCH ENTITY DETAILS
	getEntityDetails(entityId?: String) {
		return this.afStore.doc<Entity>(`entities/${entityId}`).valueChanges();
	}

	getUserEntityDetails(entityId: String) {
		const userID = sessionStorage.getItem('userId');
		return this.afStore.doc(`users/${userID}/entities/${entityId}`).valueChanges();
	}

	getUsersForEntity() {
		return this.afStore.collection(`entities/${this.entityId}/users`, ref => ref.orderBy('firstname', 'asc').where('active', '==', true)).valueChanges({ idField: 'id' });
	}

	getPropertiesForEntity() {
		return this.afStore.collection(`entities/${this.entityId}/properties`, ref => ref.orderBy('property_number', 'asc').where('active', '==', true)).valueChanges();
	}

	getSubEntitiesForEntity() {
		return this.afStore.collection(`entities/${this.entityId}/subentities`).valueChanges();
	}

	setEntity(entity: Entity): Promise<any> {
		const userID = sessionStorage.getItem('userId');
		this.entity.next(entity);
		this.currentEntity = entity;
		this.entityId = entity.uid;
		this.store.dispatch(setEntityData(entity));

		if (entity.name === 'Whitfields') {
			this.store.dispatch(setEntityId({ entityId: 'whitfields' }));
			this.store.dispatch(setEntityWhitfieldsPrefix({ whitfieldsPrefix: 'WPM' }));
		} else {
			this.store.dispatch(setEntityId({ entityId: entity.uid }));
			this.store.dispatch(setEntityWhitfieldsPrefix({ whitfieldsPrefix: entity.whitfieldsPrefix ? entity.whitfieldsPrefix : '' }));
		}

		// this.afStore
		// 	.doc<IncomingEntity>(`entities/${this.entityId}`)
		// 	.get()
		// 	.subscribe(entity => {
		// 		this.store.dispatch(setEntityData(entity.data()));
		// 	});

		if (entity.product === 'whitfields') {
			sessionStorage.setItem('whitfieldsPrefix', entity.whitfieldsPrefix);
		}

		sessionStorage.setItem('entity_name', entity.name);
		sessionStorage.setItem('entity_type', entity.entity_type);

		// UNSUBSCRIBE FROM PREVIOUS ENTITY PERMISSIONS AND FLUSH OUT PERMISSIONS
		if (this.permissionsSubscription) {
			this.permissionsSubscription.unsubscribe();
		}

		// FETCH ENTITY PERMISSIONS
		this.permissionsSubscription = this.getUserPermissionsForEntity(userID)
			.pipe(take(1))
			.subscribe((userEntityPermissions: any) => {
				if (userEntityPermissions.permissions) {
					this.permissions.next(userEntityPermissions.permissions);
					if (environment.product !== 'whitfields') {
						this.permissionsService.loadPermissions(userEntityPermissions.permissions);
					} else {
						this.auth.loadUserRoles();
					}
				}
			});
		return Promise.resolve();
	}

	getCurrentEntityID() {
		return this.entityId;
	}

	getCurrentEntityName() {
		return sessionStorage.getItem('entity_name');
	}

	addEntity(entity: Entity) {
		entity.product = environment.product;
		entity.deployed = false;
		entity.active = true;
		entity.createdby = sessionStorage.getItem('userId');
		entity.created = Date.now();
		return this.afStore.collection(`entities`).add(entity);
	}

	updateEntity(entity: Entity) {
		const entityName = sessionStorage.getItem('entity_name');
		this.entityDoc = this.afStore.doc(`entities/${this.entityId}`);
		return this.entityDoc.update(entity).then(() => {
			//ADD LOG ENTRY
			let logData = {
				name: entityName,
				description: 'Entity details updated',
				type: 'update',
				category: 'entities',
				created: Date.now(),
			};
			this.auditLogService.addAudit(logData);
		});
	}

	updateEntityTemplate(entityID, entityTemplate: any, entityContactPersonDetails: any, entitySocialLinks: any) {
		this.entityTemp = this.afStore.doc(`entities/${entityID}`);
		entityTemplate.contact_person_details = entityContactPersonDetails;
		entityTemplate.social_links = entitySocialLinks;
		this.entityTemp.set(
			{
				template: entityTemplate,
			},
			{ merge: true }
		);
	}

	updateEntityContact(primaryContactId) {
		const entityContact = this.afStore.doc(`entities/${this.entityId}`);
		entityContact.set(primaryContactId, { merge: true });
	}

	getEntityCounts() {
		this.entityDoc = this.afStore.doc(`entities/${this.entityId}`);
		return this.entityDoc.valueChanges();
	}

	collectData(entity: Entity) {
		this.itemCollection = this.afStore.collection('entities', ref => {
			return ref.where('domain', '==', entity.domain);
		});
		return this.afStore.collection(`entities`, ref => ref.orderBy('name', 'asc')).valueChanges({ idField: 'id' });
	}

	// Entity template uploads
	uploadEntityWebsiteImage(entityID, imageType, imageData = null) {
		const fileName = `${new Date().getTime()}_${imageType.toString().toLowerCase()}.png`;
		const path = `entities/${entityID}/templates/${fileName}`;
		const task = this.storage.upload(path, imageData);
		const ref = this.storage.ref(path);
		//this.uploadPercent = task.percentageChanges();`

		return new Promise<void>((resolve, reject) => {
			task.snapshotChanges()
				.pipe(
					finalize(() => {
						const downloadURL = ref.getDownloadURL();
						downloadURL.subscribe(url => {
							const key = 'template.download' + imageType;
							const data = { [key]: url };
							this.saveDataToFirestore(entityID, data)
								.then(() => {
									return resolve();
								})
								.catch(err => {
									return reject('Failed to save data to firestore: ' + err);
								});
						});
					})
				)
				.subscribe();
		});
	}

	private saveDataToFirestore(entityID, downloadData) {
		const entityRef = this.afStore.doc(`entities/${entityID}`);

		return entityRef.update(downloadData);
	}

	uploadContactPersonImage(entityID, contactPersonImage = null, contactPersonImageBase64 = '') {
		const fileName = `${new Date().getTime()}_contact_person.png`;
		const path = `entities/${entityID}/templates/${fileName}`;
		const task = this.storage.upload(path, contactPersonImage);
		const ref = this.storage.ref(path);

		return new Promise<void>((resolve, reject) => {
			task.snapshotChanges()
				.pipe(
					finalize(() => {
						const downloadURL = ref.getDownloadURL();
						downloadURL.subscribe(url => {
							this.saveContactPersonDataFirestore(entityID, url, contactPersonImageBase64)
								.then(() => {
									return resolve();
								})
								.catch(err => {
									return reject('Failed to save data to firestore: ' + err);
								});
						});
					})
				)
				.subscribe();
		});
	}

	private saveContactPersonDataFirestore(entityID, downloadData, contactPersonImage) {
		const entityRef = this.afStore.doc(`entities/${entityID}`);
		let template;
		return entityRef
			.snapshotChanges()
			.pipe(take(1))
			.toPromise()
			.then((snap: any) => {
				return this.generateThumbnailService.generateFromImage(contactPersonImage, 333, 443, 1, thumbnailData => {
					template = snap.payload.data().template;
					if (!template.contact_person_details) {
						template.contact_person_details = {};
					}
					template.contact_person_details.downloadContactPersonImage = downloadData;
					template.contact_person_details.downloadContactPersonImageThumbnail = thumbnailData;
					return entityRef.update({ template: template });
				});
			});
	}

	uploadBrochure(entityID, selectedFiles) {
		let file = selectedFiles.item(0);
		if (file.type.split('/')[1] !== 'pdf') {
			console.error('unsupported file type :( ');
			return;
		}

		const fileName = `${new Date().getTime()}_brochure.pdf`;
		const path = `entities/${entityID}/templates/${fileName}`;
		const task = this.storage.upload(path, file);
		const ref = this.storage.ref(path);

		task.percentageChanges().subscribe(value => {
			this.progressBarValue = value.toFixed(2);
		});

		return new Promise<void>((resolve, reject) => {
			task.snapshotChanges()
				.pipe(
					finalize(() => {
						const downloadURL = ref.getDownloadURL();
						downloadURL.subscribe(url => {
							this.saveBrochureDataFirestore(entityID, url)
								.then(() => {
									return resolve();
								})
								.catch(err => {
									return reject('Failed to save data to firestore: ' + err);
								});
						});
					})
				)
				.subscribe();
		});
	}

	private saveBrochureDataFirestore(entityID, downloadData) {
		const entityRef = this.afStore.doc(`entities/${entityID}`);
		let template;
		return entityRef
			.snapshotChanges()
			.pipe(take(1))
			.toPromise()
			.then((snap: any) => {
				template = snap.payload.data().template;
				template.downloadBrochure = downloadData;
				return entityRef.update({ template: template });
			});
	}

	fetchSponsors(entityID) {
		this.sponsorsCollection = this.afStore.collection(`entities/${entityID}/serviceProviders`, ref => ref.where('active', '==', true).where('sponsor', '==', true).limit(5));

		return this.sponsorsCollection.valueChanges({ idField: '' });
	}

	deleteSponsor(entityID: string, sponsorID: string) {
		const spRef = this.afStore.doc(`entities/${entityID}/serviceProviders/${sponsorID}`);

		spRef.set(
			{
				sponsor: false,
			},
			{ merge: true }
		);
	}

	fetchGalleryImages(entityID) {
		this.galleryCollection = this.afStore.collection(`entities/${entityID}/gallery`, ref => ref.orderBy('name', 'asc'));

		return this.galleryCollection.valueChanges({ idField: 'id' });
	}

	deleteGalleryImage(entityID, imageID) {
		this.deleteImage = this.afStore.doc(`entities/${entityID}/gallery/${imageID}`);
		return this.deleteImage.delete();
	}

	deleteBrochure(entityID) {
		const entityRef = this.afStore.doc(`entities/${entityID}`);
		return entityRef.update({
			'template.downloadBrochure': firebase.default.firestore.FieldValue.delete(),
		});
	}

	//PERMISSIONS
	getUserPermissionsForEntity(userId: string) {
		let entityID = '';
		if (environment['product'] !== 'olympus') {
			if (environment['product'] === 'whitfields') {
				entityID = 'whitfields';
			} else {
				entityID = this.entityId;
			}
			return this.afStore.doc(`/users/${userId}/entities/${entityID}`).valueChanges();
		} else {
			return this.afStore.doc(`/users/${userId}`).valueChanges();
		}
	}

	fetchEntityModules() {
		return this.afStore.collection(`entities/${this.entityId}/modules`).valueChanges();
	}

	searchSchemes(prefix: string) {
		const getUser = () =>
			new Promise(res => {
				this.auth.user.subscribe(user => {
					res(user);
				});
			});

		const searchEntities = user =>
			new Promise(res => {
				this.afStore
					.collection(`users/${user.uid}/entities`, ref => ref.where('product', '==', 'whitfields').where('active', '==', true))
					.snapshotChanges()
					.pipe(
						map(changes => {
							return changes.map(a => {
								const data = a.payload.doc.data() as any;
								data.id = a.payload.doc.id;
								return data;
							});
						})
					)
					.subscribe(data => {
						const results = data.filter(item => {
							let found = false;
							if (item.prefix && item.prefix.toUpperCase().includes(prefix.toUpperCase())) {
								found = true;
							}
							if (item.whitfieldsPrefix && item.whitfieldsPrefix.toUpperCase().includes(prefix.toUpperCase())) {
								found = true;
							}
							if (item.name && item.name.toUpperCase().includes(prefix.toUpperCase())) {
								found = true;
							}
							return found;
						});
						res(results);
					});
			});

		const handleSearch = async () => {
			const u = await getUser();
			const s = await searchEntities(u);
			return Promise.all([u, s]).then(d => {
				return s;
			});
		};
		return handleSearch().then(data => {
			return data;
		});
	}

	fetchEntities() {
		return this.afStore.collection(`entities`, ref => ref.where('product', '==', 'whitfields')).valueChanges({ idField: 'id' });
	}

	fetchActiveEntitiesOrderedByName() {
		return this.afStore
			.collection(`entities`, ref => ref.where('product', '==', 'whitfields').where('active', '==', true).orderBy('name', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	fetchFireflyOnlyEntities() {
		return this.afStore
			.collection(`entities`, ref => ref.where('product', '==', 'whitfields').where('isFirefly', '==', true).where('active', '==', true).orderBy('name', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	async getAllEntitiesOneTime(): Promise<Entity[]> {
		const entitiesRef = await this.afStore.collection(`entities`).ref.where('product', '==', 'whitfields').where('active', '==', true).orderBy('name', 'asc').get();
		const entities: Entity[] = entitiesRef.docs.map((doc: FixMeAny) => ({ ...doc.data(), id: doc.id }));
		return entities;
	}
}
