<div class="row" *ngIf="entity">
	<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
		<div class="m-portlet m-portlet--mobile m-portlet--head-sm">
			<!-- <app-current-entity-info></app-current-entity-info> -->
			<div class="m-portlet__body">
				<h4 class="m-0" *ngIf="entity?.name && entity?.prefix">
					{{ entity?.name }} <small>({{ entity?.prefix }}) <label class="m--font-danger"
							*ngIf="!entity?.active">(Inactive)</label></small><br />
				</h4>
				<label>
					<a href="{{ addressURL() }}" class="m-link m--font-bolder m--font-brand" target="_blank">
						<i class="fa fa-map-marker-alt"></i>
						<ng-container *ngIf="hasSomeAddress(); else showLocation">
							{{ !entity?.address ? ' ' : entity?.address + ',' }}
							{{ !entity?.suburb ? ' ' : entity?.suburb + ',' }}
							{{ !entity?.city ? ' ' : entity?.city }}
						</ng-container>
						<ng-template #showLocation> No Address Details </ng-template>
					</a>
				</label>
				<hr />
				<div class="row">
					<div class="col text-center">
						<a class="m-link m--font-bolder m--font-brand" href="#"
							routerLink="/{{ entityId }}/entities/edit" [queryParams]="{ tab: 'details' }">Scheme
							Details</a>
					</div>
					<div class="col text-center">
						<a class="m-link m--font-bolder m--font-brand" href="#"
							routerLink="/{{ entityId }}/entities/edit"
							[queryParams]="{ tab: 'physical_address' }">Scheme Address</a>
					</div>
					<div class="col text-center" *ngIf="config.headerFinancialDetailsTab">
						<a class="m-link m--font-bolder m--font-brand" href="#"
							routerLink="/{{ entityId }}/entities/edit"
							[queryParams]="{ tab: 'financial_details' }">Financial Details</a>
					</div>
				</div>
				<hr />
				<div class="row">
					<div class="col" style="border-right: 1px solid lightgrey">
						<label><i class="fa fa-code-branch"></i> {{ entity?.branch }}</label><br />
						<div class="pt-2">
							<label><i class="fa fa-building"></i> {{ entity?.entity_type }}</label>
						</div>
					</div>
					<div class="col">
						<label><i class="fa fa-users"></i> <span class="pl-2 pr-2">Total Users: </span> <span>{{
								usersCount }}</span></label><br />
						<label><i class="flaticon-layers"></i> <span class="pl-2 pr-2">Total Properties:</span> <span>{{
								propertiesCount }}</span></label>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="row">
	<div class="m-portlet">
		<div class="m-portlet__body">
			<div class="m-section m-section--last">
				<div class="m-section__content">
					<div class="m-demo" data-code-preview="true" data-code-html="true" data-code-js="false">
						<div class="m-demo__preview">
							<div class="m-nav-grid">
								<div class="m-nav-grid__row">
									<a href="#" class="m-nav-grid__item"
										routerLink="/{{ entityId }}/management/db/scheme">
										<i class="m-nav-grid__icon flaticon-squares-3"></i>
										<span class="m-nav-grid__text">Our Scheme</span>
									</a>
									<a href="#" class="m-nav-grid__item"
										routerLink="/{{ entityId }}/management/db/team">
										<i class="m-nav-grid__icon flaticon-users"></i>
										<span class="m-nav-grid__text">Our Team</span>
									</a>
									<a href="#" class="m-nav-grid__item">
										<i class="m-nav-grid__icon flaticon-browser"
											(click)="navigateToTab('scheme_website')"></i>
										<span class="m-nav-grid__text">
											<!-- button -->
											<span class="m-nav-grid__text btn-sm dropdown-toggle" data-toggle="dropdown"
												aria-expanded="false" aria-haspopup="true">Our Website</span>
											<div class="dropdown-menu" x-placement="bottom-start"
												style="position: absolute; transform: translate3d(0px, 31px, 0px); top: 0px; left: 0px; will-change: transform">
												<a class="dropdown-item" (click)="navigateToTab('scheme_website')">Edit
													Website Details</a>
												<a class="dropdown-item" (click)="navigateToTab('addons')">Edit Website
													Add-ons</a>
												<a *ngIf="!entity?.disableClientSiteDeploy; else showCustomUrl"
													class="dropdown-item" title="{{ entity?.domains[0] }}" href="#"
													(click)="navToClient()">View Website</a>
												<ng-template #showCustomUrl>
													<a class="dropdown-item" title="{{ entity?.websiteUrl }}"
														href="{{ entity?.websiteUrl }}" target="_blank">View Website</a>
												</ng-template>
											</div>
											<!-- button end -->
										</span>
									</a>
									<a href="#" class="m-nav-grid__item"
										routerLink="/{{ entityId }}/management/db/service-providers-dashboard">
										<i class="m-nav-grid__icon flaticon-business"></i>
										<span class="m-nav-grid__text">Our Service Providers</span>
									</a>
								</div>
								<div class="m-nav-grid__row">
									<a href="#" class="m-nav-grid__item"
										routerLink="/{{ entityId }}/management/db/finance-dashboard">
										<i class="m-nav-grid__icon flaticon-piggy-bank"></i>
										<span class="m-nav-grid__text">Finance</span>
									</a>
									<a href="#" class="m-nav-grid__item" routerLink="/{{ entityId }}/communications">
										<i class="m-nav-grid__icon flaticon-chat"></i>
										<span class="m-nav-grid__text">Communication</span>
									</a>
									<a href="#" routerLink="/{{ entityId }}/management/db/insurance"
										class="m-nav-grid__item">
										<i class="m-nav-grid__icon flaticon-file"></i>
										<span class="m-nav-grid__text">Insurance</span>
									</a>
									<a href="#" routerLink="/{{ entityId }}/management/db/maintenance"
										class="m-nav-grid__item">
										<i class="m-nav-grid__icon flaticon-cogwheel-1"></i>
										<span class="m-nav-grid__text">Maintenance</span>
									</a>
								</div>
								<div class="m-nav-grid__row">
									<a href="#" class="m-nav-grid__item"
										routerLink="/{{ entityId }}/management/db/meetings">
										<i class="m-nav-grid__icon flaticon-calendar-1"></i>
										<span class="m-nav-grid__text">Meetings</span>
									</a>
									<a href="#" class="m-nav-grid__item"
										routerLink="/{{ entityId }}/management/db/disputes-dashboard">
										<i class="m-nav-grid__icon flaticon-exclamation-1"></i>
										<span class="m-nav-grid__text">Disputes</span>
									</a>
									<a href="#" class="m-nav-grid__item">
										<i class="m-nav-grid__icon flaticon-computer"></i>
										<span class="m-nav-grid__text">Community Management</span>
									</a>
									<a href="#" class="m-nav-grid__item">
										<i class="m-nav-grid__icon flaticon-support"></i>
										<span class="m-nav-grid__text">On-Site Management</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="row">
	<div class="col-xl-12">
		<div class="row">
			<div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
				<app-current-balances *ngIf="!isLoading"></app-current-balances>
			</div>
			<!-- <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
            <app-age-analysis *ngIf="!isLoading"
              (isView)='isTableView($event)'
              (viewData)='setViewData($event)'>
            </app-age-analysis>
        </div> -->
			<div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
				<app-accounts-report [hidden]="isLoading"></app-accounts-report>
			</div>
		</div>
	</div>
</div>

<!-- <div class="modal fade" id="m_age_analysis" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" style="max-width: 80vw !important;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          View Age Analysis
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleAgeAnalysisView()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="isAgeAnalysisView" class="modal-body">
        <app-age-analysis-table [ageAnalysisData]="AgeAnalysisData"></app-age-analysis-table>
        <div id="tableView"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="toggleAgeAnalysisView()">Close</button>
      </div>
    </div>
  </div>
</div> -->

<div class="row" #target *ngIf="config === 'whitfields'">
	<div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
		<app-management-team></app-management-team>
	</div>
	<div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
		<app-managers></app-managers>
	</div>
</div>