import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Store } from '@ngrx/store';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { AuthenticationService } from '@auth/_services';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize, map } from 'rxjs/operators';
import { Entity } from '../entities/entities.model';
import { Account } from '../../plex/fin/models/account';

@Injectable()
export class ManagementServiceProvidersService {
	entityId: string;
	loggedInUser: any;
	constructor(
		public afStore: AngularFirestore,
		private afAuth: AngularFireAuth,
		private store: Store,
		private authService: AuthenticationService,
		private storage: AngularFireStorage
	) {
		// this.store.select(selectEntityId).subscribe((entityId) => {
		// 	this.entityId = entityId;
		// });
		this.authService.user.subscribe(user => {
			this.loggedInUser = user;
		});
	}

	public async fetchWhifielAccounts() {
		const whiEntity = (await this.afStore.collection<Entity>(`entities`).ref.where('whitfieldsPrefix', '==', 'WHI').get()).docs.map(entitySnap => ({
			id: entitySnap.id,
			...entitySnap.data(),
		}))[0];

		if (!whiEntity) {
			throw new Error(`No entity where 'whitfieldsPrefix', '==', 'WHI'`);
		}

		return this.afStore
			.collection<Account>(`/entities/${whiEntity.id}/fin/accounts/list`)
			.valueChanges({ idField: 'id' })
			.pipe(
				map(account =>
					account.filter(({ name }) => {
						const partialAccountName = name.toLowerCase().trim().slice(0, 7);
						return partialAccountName.includes('whifiel');
					})
				)
			);
	}

	async checkIfServiceProviderExists(spName: string) {
		const doesNameExist = await this.afStore.collection(`entities/whitfields/serviceProviders`).ref.where('name', '==', spName).get();
		if (doesNameExist.size > 0) {
			return true;
		}
		return false;
	}

	fetchServiceProviderTypeList(spType: string) {
		return this.afStore.collection('entities/whitfields/serviceProviders', ref => ref.where('tags', 'array-contains', spType)).valueChanges({ idField: 'id' });
	}

	verifyServiceProviderForEntitySponsors(entityId) {
		return new Promise((res, rej) => {
			this.afStore
				.collection(`entities/${entityId}/serviceProviders`)
				.ref.where('isSponsor', '==', true)
				.get()
				.then(sponsorDocs => {
					if (sponsorDocs.size >= 5) {
						rej('Entity already has 5 sponsors.');
					} else {
						res('');
					}
				});
		});
	}

	fetchServiceProviders() {
		return this.afStore.collection('entities', ref => ref.where('product', '==', 'serve').where('active', '==', true).orderBy('name', 'asc')).valueChanges({ idField: 'id' });
	}

	fetchProductServiceProviders() {
		return this.afStore
			.collection('entities', ref => ref.where('entity_product', '==', 'whitfields').where('active', '==', true).orderBy('name', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	fetchServiceProvider(entityId: String) {
		return this.afStore.doc(`entities/${entityId}`).valueChanges();
	}

	async updateServiceProvider(entityId: String, spData: any, entities: any[]) {
		await entities.forEach(async entity => {
			await this.afStore.doc(`entities/${entity.id}/serviceProviders/${entityId}`).set(spData, { merge: true });
		});

		await this.afStore.doc(`entities/whitfields/serviceProviders/${entityId}`).set(spData, { merge: true });

		await this.afStore.doc(`entities/${entityId}`).set(spData, { merge: true });
	}

	removeServiceProvider(entityId: String) {
		return this.afStore.doc(`entities/${entityId}`).set(
			{
				active: false,
			},
			{ merge: true }
		);
	}

	async updateServiceProviderEntity(spId: String, updateData, entityId) {
		await this.afStore.doc(`entities/${entityId}/serviceProviders/${spId}`).set(updateData, { merge: true });
		await this.afStore.doc(`entities/${spId}/entities/${entityId}`).set(updateData, { merge: true });
	}

	fetchServiceProviderUsers(spId) {
		return this.afStore.collection(`entities/${spId}/users`, ref => ref.where('active', '==', true)).valueChanges({ idField: 'id' });
	}

	// Associated Entities
	fetchEntities() {
		return this.afStore
			.collection('entities', ref => ref.where('product', '==', environment.product).where('active', '==', true).orderBy('name', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	async unlinkEntityFromServiceProvider(spId, entityId) {
		await this.afStore.doc(`entities/${spId}/entities/${entityId}`).set({ active: false }, { merge: true });

		await this.afStore.doc(`entities/${entityId}/serviceProviders/${spId}`).set({ active: false }, { merge: true });
	}

	fetchServiceProvidersForEntity(entityId) {
		return this.afStore.collection(`entities/${entityId}/serviceProviders`, ref => ref.where('active', '==', true)).valueChanges({ idField: 'id' });
	}

	fetchServiceProviderEntities(spId) {
		return this.afStore.collection(`entities/${spId}/entities`, ref => ref.where('active', '==', true)).valueChanges({ idField: 'id' });
	}

	async asyncForEach(array, callback) {
		for (let index = 0; index < array.length; index++) {
			await callback(array[index], index, array);
		}
	}

	async fetchServiceProviderEntitiesBilling(serviceProviders) {
		const spEntitiesArr = {};
		const handler = async () => {
			await this.asyncForEach(serviceProviders, async provider => {
				const entityDocs = await this.afStore.collection(`entities/${provider.id}/entities`).ref.where('active', '==', true).get();
				if (entityDocs.size > 0) {
					spEntitiesArr[provider.id] = provider;
					spEntitiesArr[provider.id]['entities'] = [];
					await entityDocs.forEach(async entityDoc => {
						const entity: any = entityDoc.data();
						if (entity.isSponsor || entity.isLocal) {
							spEntitiesArr[provider.id].entities.push(entity);
						}
						return Promise.resolve();
					});
				}
			});
		};
		await handler();
		return Promise.resolve(spEntitiesArr);
	}

	getAccounts(spId) {
		return this.afStore.collection(`entities/${spId}/fin/accounts/list`, ref => ref.where('active', '==', true)).valueChanges({ idField: 'id' });
	}

	async addAccount(account: Account, entityId: string) {
		const { id = this.afStore.createId() } = account;

		const accountCopy = {
			...account,
			active: true,
			createdBy: this.loggedInUser.uid,
			created: Date.now(),
			balance: 0,
			category: 'accounts',
			whitfieldsType: 'serviceProvider',
		};

		await this.afStore.doc(`entities/${entityId}/fin/accounts/list/${id}`).set(accountCopy);
		await this.afStore.doc(`entities/whitfields/fin/accounts/list/${id}`).set(accountCopy);
	}

	editAccount() {}

	async removeAccount(account, entityId) {
		await this.afStore.doc(`entities/${entityId}/fin/accounts/list/${account.id}`).set({ active: false }, { merge: true });
		await this.afStore.doc(`entities/whitfields/fin/accounts/list/${account.id}`).set({ active: false }, { merge: true });
	}

	async removeUser(user, entityId) {
		await this.afStore.doc(`entities/${entityId}/users/${user.id}`).set({ active: false }, { merge: true });
		await this.afStore.doc(`users/${user.id}/entities/${entityId}`).set({ active: false }, { merge: true });
	}

	getDocumentsForProvider(entityId) {
		return this.afStore.collection(`entities/${entityId}/documents/folders/list/servicProviders/docs`, ref => ref.where('active', '==', true)).valueChanges({ idField: 'id' });
	}

	removeDocument(entityId, doc) {
		return this.afStore.doc(`entities/${entityId}/documents/folders/list/servicProviders/docs/${doc}`).set({ active: false }, { merge: true });
	}

	uploadImage(upload = null, imageName: string, spDetails, entities, field = 'downloadLogo') {
		const fileName = `${new Date().getTime()}_${imageName}`;
		const path = `entities/${spDetails.id}/files/${fileName}.png`;
		const task = this.storage.upload(path, upload);
		const ref = this.storage.ref(path);

		return new Promise((resolve, reject) => {
			task.snapshotChanges()
				.pipe(
					finalize(() => {
						const downloadURL = ref.getDownloadURL();
						downloadURL.subscribe(url => {
							const file = {
								template: {
									[field]: url,
								},
							};
							return this.updateServiceProvider(spDetails.id, file, entities).then(() => resolve('success'));
						});
					})
				)
				.subscribe();
		});
	}

	generateComplexPrefix(name) {
		//first clean the complex of numbers

		var tmpComplex = this.cleanUpComplex(name);
		var splitComplex = tmpComplex.split(' '); //split on space now and get count
		splitComplex = splitComplex.filter(Boolean);
		var wordCount = splitComplex.length;

		var columnPrefix = '';
		if (wordCount == 1) {
			columnPrefix = this.generateComplexPrefixOneWord(splitComplex);
		} else if (wordCount == 2) {
			columnPrefix = this.generateComplexPrefixTwoWords(splitComplex);
		} else if (wordCount == 3) {
			columnPrefix = this.generateComplexPrefixThreeWords(splitComplex);
		} else if (wordCount == 4) {
			columnPrefix = this.generateComplexPrefixFourWords(splitComplex);
		} else {
			columnPrefix = this.generateComplexPrefixMultiWords(splitComplex);
		}

		return columnPrefix;
	}

	cleanUpComplex(value) {
		//var valueTmp = value.replace(/[0-9]/g, ''); //clear numbers
		const cleanWords = ['of', 'oF', 'OF', 'Of', 'on', 'oN', 'ON', 'On', 'HOA'];
		var valueTmp = value;
		value = valueTmp.replace(/[^\w\s]/gi, ''); //clear special characters
		cleanWords.forEach(tmpValue => {
			var regEx = ' ' + tmpValue;
			value = value.replace(regEx, '');
		});
		return value;
	}

	generateComplexPrefixOneWord(details) {
		//Name only 1 use 7 letters
		var tmpComplex = details[0];
		return tmpComplex.substring(0, 7).toUpperCase();
	}

	generateComplexPrefixTwoWords(details) {
		//Name only 2 words user first two letters and last two of first word and first two letters and last letter of second word
		var tmpComplex1 = details[0];
		var tmpComplex2 = details[1];

		tmpComplex1 = tmpComplex1.substring(0, 2).toUpperCase() + tmpComplex1.substring(tmpComplex1.length - 2).toUpperCase();
		tmpComplex2 = tmpComplex2.substring(0, 2).toUpperCase() + tmpComplex2.substring(tmpComplex2.length - 1).toUpperCase();
		return tmpComplex1 + tmpComplex2;
	}

	generateComplexPrefixThreeWords(details) {
		//Name only 3 use last letter of second and last name
		var tmpComplex1 = details[0];
		var tmpComplex2 = details[1];
		var tmpComplex3 = details[2];

		tmpComplex1 = tmpComplex1.substring(0, 2).toUpperCase() + tmpComplex1.substring(tmpComplex1.length - 1).toUpperCase();
		tmpComplex2 = tmpComplex2.substring(0, 1).toUpperCase() + tmpComplex2.substring(tmpComplex2.length - 1).toUpperCase();
		tmpComplex3 = tmpComplex3.substring(0, 1).toUpperCase() + tmpComplex3.substring(tmpComplex3.length - 1).toUpperCase();

		return tmpComplex1 + tmpComplex2 + tmpComplex3;
	}

	generateComplexPrefixFourWords(details) {
		//First Letter from each word in name plus last letter of last word
		var tmpComplex1 = details[0];
		var tmpComplex2 = details[1];
		var tmpComplex3 = details[2];
		var tmpComplex4 = details[3];

		tmpComplex1 = tmpComplex1.substring(0, 1).toUpperCase() + tmpComplex1.substring(tmpComplex1.length - 1).toUpperCase();
		tmpComplex2 = tmpComplex2.substring(0, 1).toUpperCase() + tmpComplex2.substring(tmpComplex2.length - 1).toUpperCase();
		tmpComplex3 = tmpComplex3.substring(0, 1).toUpperCase();
		tmpComplex4 = tmpComplex4.substring(0, 1).toUpperCase() + tmpComplex4.substring(tmpComplex4.length - 1).toUpperCase();
		return tmpComplex1 + tmpComplex2 + tmpComplex3 + tmpComplex4;
	}

	generateComplexPrefixMultiWords(details) {
		//First Letter from each word in name
		var tmpComplex1 = details[0];
		var tmpComplex2 = details[1];
		var tmpComplex3 = details[2];
		var tmpComplex4 = details[3];
		var tmpComplex5 = details[4];

		tmpComplex1 = tmpComplex1.substring(0, 1).toUpperCase() + tmpComplex1.substring(tmpComplex1.length - 1).toUpperCase();
		tmpComplex2 = tmpComplex2.substring(0, 1).toUpperCase();
		tmpComplex3 = tmpComplex3.substring(0, 1).toUpperCase();
		tmpComplex4 = tmpComplex4.substring(0, 1).toUpperCase();
		tmpComplex5 = tmpComplex5.substring(0, 1).toUpperCase() + tmpComplex5.substring(tmpComplex5.length - 1).toUpperCase();
		return tmpComplex1 + tmpComplex2 + tmpComplex3 + tmpComplex4 + tmpComplex5;
	}
}
